import { serviceTypes } from './services.types';

const INITIAL_STATE = {

  loading: false,
  loading_cats: false,
  loading_classes: false,
  loading_tax_heads: false,
  getting_payment_option: false,
  is_applying: false,
  is_verifying_nin: false,
  application_status: {
    prnResponse: {
      prn: ""
    },
    applicationResponse: {
      applicant_id: {
        surname: "",
        other_name: "",
        company_name: ""
      }
    },
    finalAppResponse: {
      related_transaction_type: {
        amount: "",
        name: ""
      }
    }
  },
  submitted_application_list: [],
  application_res: {
    "application_id":
      { "id": 0, "applicant_id": { "id": 0, "surname": "", "other_names": "", "citizenship": "", "email_address": "", "applicant_type": "", "id_type": "", "id_number": "", "company_name": null, "business_registration_number": null, "address": null, "country_of_origin": null, "phone_contact": null, "gender": "", "date_of_birth": null }, "application_number": null, "related_transaction_type": 0, "license_plate_no": null, "physical_location": "", "created_at": null }, "related_transaction_type": { "id": null, "name": null, "service_class_id": { "id": null, "name": null, "service_option_id": 0, "description": "" }, "service_option_id": { "id": 0, "name": null, "service_id": null, "description": "" }, "tax_head_code": null, "amount": null }, "payment_option_id": { "id": 1, "name": null, "description": "" }, "prn": null
  },
  current_application_status: {},
  message: '',
  data: [],
  service_categories: [],
  service_classes: [],
  service_tax_heads: [],
  payment_options: [],
  errors: {},
  updating_user_input: false,
  resetting_user_input: false,
  number_plate_verified: false,
  nin_verified: false,
  selected_services: {
    service_id: "",
    service_name: "",
    service_class: "",
    service_category: "",
    service_tax_head: "",
    service_fee: 0,
    number_plate: "",
    foreign_number_plate: "",
    number_plate_type: "",
    applicant: "", //ugandan, non-ugandan, refugee, company, individual
    id_type: "", //nin, passport, refugee cert
    id_number: "",
    gender: "", //male, female, company
    reg_name: "", // company name,
    given_names: "",            //individual name
    sur_name: "",
    op_sur_name: "",
    op_given_names: "",
    other_name: "",
    mobile_number: "",
    nin_verified_name: "",
    dob: "",
    email_address: "",
    company_reg_no: "",
    physical_location: "",
    tin_number: "",
    country_of_origin: "",
    hide_side_overview: false,
    payment_option: "",
    address: "",
    captcha_code: "",
    valid_captcha: null,
    hasCat: true,
    applicant_is_owner: false,
  },
  is_getting_app_confirmation: false,
  is_getting_app_status: false,
  applicationConfirmationData: {},
  is_valid: false,
  is_getting_stats: false,
  dash_stats: {
    expired_PRNs: 0,
    missed_revenue: 0,
    revenue_breakdown: [
      {
        color: "#764abc",
        name: "loading",
        percentage: 0,
        revenue: 0
      }
    ],
    revenue_collected: 0,
    total_transactions: 0,
    transaction_breakdown: [
      {
        color: "#764abc",
        name: "loading",
        percentage: 0,
        transactions: 0
      }
    ],
    top_transactions_generators: [],
    top_revenue_generators: [],
    revenue_bk_by_category: [{ name: "loading", revenue: 0 }], //bk - breakdwon
    tx_bk_by_category: [{ name: "loading", count: 0 }],
  }
};

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

    case serviceTypes.FETCH_SERVICES_STARTED:
      return {
        ...state, loading: true
      };

    case serviceTypes.FETCH_SERVICES_SUCCESS:
      return {
        ...state, loading: false, data: action.payload.data
      };

    case serviceTypes.FETCH_SERVICES_CAT_STARTED:
      return {
        ...state, loading_cats: true,
      };
    case serviceTypes.FETCH_SERVICES_CAT_SUCCESS:
      return {
        ...state, loading_cats: false, service_categories: action.payload
      };
    case serviceTypes.FETCH_SERVICES_CAT_FAILED:
      return {
        ...state, loading_cats: false, errors: action.payload.data
      };

    case serviceTypes.FETCH_SERVICES_CLASS_STARTED:
      return {
        ...state, loading_classes: true,
      };
    case serviceTypes.FETCH_SERVICES_CLASS_SUCCESS:
      return {
        ...state, loading_classes: false, service_classes: action.payload
      };
    case serviceTypes.FETCH_SERVICES_CLASS_FAILED:
      return {
        ...state, loading_classes: false, errors: action.payload.data
      };

    case serviceTypes.FETCH_SERVICES_TAXHEAD_STARTED:
      return {
        ...state, loading_tax_heads: true,
      };
    case serviceTypes.FETCH_SERVICES_TAXHEAD_SUCCESS:
      return {
        ...state, loading_tax_heads: false, service_tax_heads: action.payload
      };
    case serviceTypes.FETCH_SERVICES_TAXHEAD_FAILED:
      return {
        ...state, loading_tax_heads: false, errors: action.payload.data
      };

    case serviceTypes.UPDATE_USER_INPUT_STARTED:
      return {
        ...state, updating_user_input: true,
      };
    case serviceTypes.UPDATE_USER_INPUT_SUCCESS:
      state["selected_services"][action.payload.name] = action.payload.value
      state["error"][action.payload.name] = action.payload.err
      return {
        ...state, updating_user_input: false,
      };
    case serviceTypes.UPDATE_USER_INPUT_FAILED:
      return {
        ...state, updating_user_input: false, errors: action.payload
      };

    case serviceTypes.RESET_USER_INPUT_STARTED:
      return {
        ...state, resetting_user_input: true,
      };
    case serviceTypes.RESET_USER_INPUT_SUCCESS:
      return {
        ...state, resetting_user_input: false, selected_services: action.payload, service_categories: [],
        service_classes: [],
        service_tax_heads: [],
        number_plate_verified: false,
        nin_verified: false,
        applicant_is_owner: false
      };
    case serviceTypes.RESET_USER_INPUT_FAILED:
      return {
        ...state, resetting_user_input: false, errors: action.payload
      };

    case serviceTypes.FETCH_PAYMENT_OPTION_STARTED:
      return {
        ...state, getting_payment_option: true,
      };
    case serviceTypes.FETCH_PAYMENT_OPTION_SUCCESS:
      return {
        ...state, getting_payment_option: false, payment_options: action.payload,
      };
    case serviceTypes.FETCH_PAYMENT_OPTION_FAILED:
      return {
        ...state, getting_payment_option: false, errors: action.payload
      };

    case serviceTypes.SUBMIT_APPLICATION_STARTED:
      return {
        ...state, is_applying: true,
      };
    case serviceTypes.SUBMIT_APPLICATION_SUCCESS:
      return {
        ...state, is_applying: false, application_res: action.payload,
      };
    case serviceTypes.SUBMIT_APPLICATION_FAILED:
      return {
        ...state, is_applying: false,
      };

    case serviceTypes.GET_PRN_STARTED:
      return {
        ...state, is_getting_prn: true,
      };
    case serviceTypes.GET_PRN_SUCCESS:
      return {
        ...state, is_getting_prn: false, prn_data: action.payload,
      };
    case serviceTypes.GET_PRN_FAILED:
      return {
        ...state, is_getting_prn: false, errors: action.payload
      };

    case serviceTypes.ATTACH_PRN_STARTED:
      return {
        ...state, is_attaching_prn: true,
      };
    case serviceTypes.ATTACH_PRN_SUCCESS:
      return {
        ...state, is_attaching_prn: false, payslip: action.payload,
      };
    case serviceTypes.ATTACH_PRN_FAILED:
      return {
        ...state, is_attaching_prn: false, errors: action.payload
      };

    case serviceTypes.VALIDATE_STEP_SUCCESS:
      return {
        ...state, is_valid: action.payload
      }

    case serviceTypes.VERIFY_NUMBER_PLATE_STARTED:
      return {
        ...state, is_verifying_plateno: true
      };
    case serviceTypes.VERIFY_NUMBER_PLATE_SUCCESS:

      const name = action.payload.registered_owner.split(" ")

      const [surname, ...given_names] = name
      state["selected_services"]['sur_name'] = surname
      state["selected_services"]['given_names'] = given_names.toString().replace(',', " ")

      state["selected_services"]['registered_vehicle_owner'] = action.payload.registered_owner
      state["selected_services"]['registered_vehicle_make'] = action.payload.make
      state["selected_services"]['registered_vehicle_capacity'] = action.payload.capacity
      state['selected_services']['apply_capacity_filter'] = action.payload.apply_capacity_filter
      state['selected_services']['tonnage'] = action.payload?.tonnage
state['selected_services']['tin_number'] = action.payload.tin_no
      return {
        ...state, number_plate_verified: true, is_verifying_plateno: false
      };
    case serviceTypes.VERIFY_NUMBER_PLATE_FAILED:
      return {
        ...state, errors: action.payload, number_plate_verified: false, is_verifying_plateno: false
      };

    case serviceTypes.VERIFY_NIN_STARTED:
      return {
        ...state, is_verifying_nin: true
      };
    case serviceTypes.VERIFY_NIN_SUCCESS:
      const nin_name = action.payload.name.split(" ")

      const [nin_surname, ...nin_given_names] = nin_name
      state["selected_services"]['nin_surname'] = nin_surname
      state["selected_services"]['nin_othername'] = nin_given_names.toString().replaceAll(',', " ").replace(/\s+/g, ' ').trim()
                state['selected_services']['nin_verified_name'] = action.payload.name
      state['selected_services']['dob'] = action.payload.dob
      state['selected_services']['gender'] = action.payload.gender

      return {
        ...state, nin_verified: true, is_verifying_nin: false
      };
    case serviceTypes.VERIFY_NIN_FAILED:
      return {
        ...state, errors: action.payload, nin_verified: false, is_verifying_nin: false,
      };

    case serviceTypes.GET_APP_CONFIRMATION_STARTED:
      return {
        ...state, is_getting_app_confirmation: true,
      };
    case serviceTypes.GET_APP_CONFIRMATION_SUCCESS:
      return {
        ...state, is_getting_app_confirmation: false, application_res: action.payload,
      };
    case serviceTypes.GET_APP_CONFIRMATION_FAILED:
      return {
        ...state, is_getting_app_confirmation: false, errors: action.payload
      };

    case serviceTypes.GET_DASHBOARD_STATS_STARTED:
      return {
        ...state, is_getting_stats: true,
      };
    case serviceTypes.GET_DASHBOARD_STATS_SUCCESS:
      return {
        ...state, is_getting_stats: false, dash_stats: { ...action.payload },
      };
    case serviceTypes.GET_DASHBOARD_STATS_FAILED:
      return {
        ...state, is_getting_stats: false, errors: action.payload
      };


    default: return state;

  }

};

export default reducer;